import iconOne from "../../assets/service/1.png";
import iconTwo from "../../assets/service/2.png";
import iconThree from "../../assets/service/3.png";
import iconFour from "../../assets/features/1.png";

const ServicesData = [
  {
    id: 1,
    icon: iconOne,
    title: "Cтрах остаться без зубов в эстетически значимой зоне?",
    description:
      "Временные коронки изготовленные до операции решают и эту проблему. После операции вы выйдете из кабинета с красивой улыбкой!",
  },
  {
    id: 2,
    icon: iconTwo,
    title: "Дискомфортно использовать протезы?",
    description:
      "Несъемные конструкции отфрезерованные из циркония или композита с опорой на 6 или 4 имплантата избавят вас от неудобств!",
  },
  {
    id: 3,
    icon: iconThree,
    title: "Имплантация одномоментно с удалением зуба?",
    description: `Имплантация одномоментно с удалением зуба? Не вопрос! Высокотехнологичный дизайн имплантов позволяет получить отличную первичную стабильность даже в условиях дефицита костной ткани. Атрофия костной ткани при одномоментной имплантации происходит в меньшем объеме, чем при двухэтапном протоколе.`,
  },
  {
    id: 4,
    icon: iconFour,
    title: "Остались вопросы ?",
    description:
      "Я готов помочь решить вам эти и многие другие проблемы. Приходите на консультацию!",
  },
];

export default ServicesData;
