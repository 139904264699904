import React from "react";
import ThemeIcon from "../ThemeIcon/ThemeIcon";
import "./Service.scss";

const Service = ({ serviceList }) => {
  const { title, description, icon } = serviceList;

  return (
    <div className="col-lg-4 single-testimoni">
      <div className="service-box">
        <div className="service-icon">
          <div className="icon-area">
            <ThemeIcon icon={icon} />
          </div>
        </div>
        <div className="service-text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
